import React, { useState, useEffect } from 'react';
import { Search, Loader2 } from 'lucide-react';
import logo from "../Assets/images/brevy.svg";

const RelatedVideos = ({ relatedVids, setRelatedVids, setYoutubeLink, youtubeLink, fetchTranscriptAndAdvice, apiUrl, showRelatedVideos}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const[entered, setEntered] = useState("")
  const [isLoading, setIsLoading] = useState(false);

  const handleVideoClick = (youtubeUrl) => {
    console.log("New link", youtubeUrl);
    setYoutubeLink(youtubeUrl);
    setEntered(youtubeUrl);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  useEffect(() => {
    if (youtubeLink) {
      fetchTranscriptAndAdvice(); // Call this when youtubeLink state changes
    }
  }, [entered]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log('Loading started:', isLoading);

    try {
      const response = await fetch(apiUrl +'/api/search_youtube', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: searchQuery }),
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      setRelatedVids(data);
    } catch (error) {
      console.error('Error searching YouTube:', error);
    } finally {
      setIsLoading(false);
      console.log('Loading ended:', isLoading);
    }
  };

  return (
    <div className="related-videos-outer-wrapper">
      <div className="related-videos-wrapper">
        <div className="sticky-header">
          <h3 className="summary-title">Related Videos:</h3>
          <div className="search-wrapper">
            <form onSubmit={handleSearchSubmit} className="search-container">
              <div className="search-input-container">
                <button type="submit" className="search-icon-button">
                  <Search className="search-icon" size={20} />
                </button>
                <input
                  type="text"
                  placeholder="Search Youtube videos..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="search-input"
                />
                {searchQuery && (
                  <button type="button" className="clear-button" onClick={() => setSearchQuery('')}>
                    &#x2715;
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        <div className="related-videos-container">
          {relatedVids && relatedVids.length > 0 && showRelatedVideos ? (
            relatedVids.map((video, index) => (
              <div 
                key={index} 
                className="video-item"
                onClick={() => handleVideoClick(video.youtube_url)}
              >
                <div className="thumbnail-container">
                  <img 
                    src={video.default_thumbnail} 
                    alt={video.title} 
                    className="thumbnail"
                  />
                  {video.duration ? <span className="duration">{video.duration || '0:00'}</span>: null}
                </div>
                <div className="video-info">
                  <h4 className="video-title">{video.title}</h4>
                  <p className="channel-name">{video.channel_name || 'Unknown Channel'}</p>
                  <p className="video-stats">
                    {video.published_date || 'Unknown date'}          
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="no-videos-message">
              {relatedVids && (
        <div className="loading-related">
          <div className="spinner"></div>
          <span className="text">Finding related videos...</span>
        </div>
      )}
            </div>
          )}
        </div>

        <style jsx>{`
          .related-videos-outer-wrapper {
            width: 100%;
            margin-top: 20px;
          }

          .loading-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
            color: #666;
          }

          .loading-spinner {
            animation: spin 1s linear infinite;
            margin-bottom: 1rem;
          }

          .no-videos-message {
            text-align: center;
            padding: 20px;
            color: #666;
          }

          .related-videos-wrapper {
            max-height: calc(15 * (136px + 16px)); /* Further increased height to show even more videos */
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 16px;
            margin-top: 20%;
            position: relative;
            margin-left: .5%;
            padding-left: 5px;
          }

          .sticky-header {
            position: sticky;
            top: 0;
            background: #f0f0f0;
            z-index: 10;
            padding: 2px;
            margin-bottom: 16px;
            border-radius: 12px;
            margin-right: -10px;
            height: 60px;
            box-shadow: 
              2px 2px 4px rgba(0,0,0,0.1),
              -1px -1px 3px rgba(255,255,255,0.5);
            border-radius: 2.5%;
            transform: translateZ(0);
            perspective: 1000px;
            transition: transform 0.2s;
          }


          .summary-title {
            font-size: 16px;
            font-weight: bold;
            color: #000000e6;
            margin-bottom: 12px;
            padding-left: 16px;
            text-align: center;
          }

          .search-wrapper {
            padding: 2px;
          }

          .search-container {
            display: flex;
            align-items: center;
            display: none;
          }

          .search-input-container {
            position: relative;
            flex-grow: 1;
          }

          .search-icon-button {
            position: absolute;
            left: 1px;
            top: 50%;
            transform: translateY(-50%);
            background: #403c409e; /* Purple background color */
            border: none;
            cursor: pointer;
            border-radius: 50%;
            padding: 8px;
            display: none;
          }

          .search-icon {
            color: #fff; /* White color for the icon */
            display: none;
          }

          .search-input {
              height: 40px;
              width: 100%;
              padding: 8px 40px;
              border: 1px solid #ccc;
              border-radius: 30px;
              font-size: 16px;
              outline: none;
              transition: all 0.3s;
              display: none;
          }

          .search-input:focus {
            border-color: #1a73e8;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          }

          .clear-button {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            font-size: 18px;
            color: #606060;
            cursor: pointer;
          }

          .related-videos-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-left: 1px;
            max-width: 410px;
            width: max-content;
          }

          .video-item {
            width: 97.5%;
            display: flex;
            cursor: pointer;
            transition: background-color 0.2s, transform 0.1s;
            border-radius: 5px !important;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          }

          .video-item:hover {
            background-color: #f2f2f2;
          }

          .thumbnail-container {
            position: relative;
            width: 180px;
            height: 110px;
            margin-right: 8px;
            flex-shrink: 0;
            border-radius: 5px !important;
          }

          .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px !important;
          }

          .duration {
            position: absolute;
            bottom: 4px;
            right: 4px;
            background-color: rgba(0, 0, 0, 0.8);
            color: white;
            padding: 1px 4px;
            border-radius: 2px;
            font-size: 12px;
          }

          .video-info {
            flex: 1;
            display: flex;
            flex-direction: column;
            min-width: 0;
          }

          .video-title {
            font-size: 14px !important;
            font-weight: 500;
            margin: 2px 2px 2px 2px;
            width: 90%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-weight: bold;
          }

          .channel-name, .video-stats {
            font-size: 12px;
            color: #606060;
            margin: 0;
          }

          .related-videos-wrapper::-webkit-scrollbar {
            width: 8px;
            background: transparent;  /* Make track transparent by default */
          }

          .related-videos-wrapper::-webkit-scrollbar-track {
            background: transparent;  /* Make track transparent by default */
          }

          .related-videos-wrapper::-webkit-scrollbar-thumb {
            background: transparent;  /* Make thumb transparent by default */
            border-radius: 4px;
            transition: background 0.2s;
          }

          .related-videos-wrapper:hover::-webkit-scrollbar-thumb {
            background: #888;  /* Show thumb on hover */
          }

          .related-videos-wrapper::-webkit-scrollbar-thumb:hover {
            background: #555;  /* Darker on thumb hover */
          }

          .related-videos-wrapper {
            scrollbar-width: thin;
            scrollbar-color: transparent transparent;  /* Both track and thumb transparent for Firefox */
          }

          .related-videos-wrapper:hover {
            scrollbar-color: #888 transparent;  /* Show scrollbar on hover for Firefox */
          }

          @media (max-width: 768px) {

           .related-videos-wrapper {
            max-height: calc(15 * (136px + 16px)); /* Further increased height to show even more videos */
            overflow-y: auto;
            overflow-x: hidden;
            padding-right: 16px;
            margin-top: 2%;
            position: relative;
          }
            .related-videos-container {
              max-width: 100%;
            }
          }

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}</style>
      </div>
    </div>
  );
};

export default RelatedVideos;