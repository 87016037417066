import React, { useState, useEffect, useCallback, useRef } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';


import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  SendButton
} from '@chatscope/chat-ui-kit-react';

const Environment = "prod"; // Set the environment here. Production: "pro", Development: "dev"

var apiURL = Environment == "dev" ? apiURL = "http://127.0.0.1:8080" : apiURL = "https://api.bevi.ai";

var webURL = Environment == "dev" ? webURL = "http://localhost:3000" : webURL = "https://bevi.ai";

const Chatbot = ({ videoURL, videoId, chromaPath, isOpen, player, isPlayerReady, onClose, setIsOpen }) => {
  const [conversation, setConversation] = useState([{ role: 'bot', content: 'Hello! Im Bevi AI Agent. Do you have any question about this video?' }]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [transcript, setTranscript] = useState([]);
  const messageListRef = useRef(null);
  const chatbotRef = useRef(null);
  const [position, setPosition] = useState({ x: 20, y: 290 }); // Adjusted initial position to be 50px lower
  const [dragging, setDragging] = useState(false);
  const [rel, setRel] = useState(null);

  // Reset the chat when videoId changes
  useEffect(() => {
    setConversation([{ role: 'bot', content: 'Hello! Do you have any question about this video?' }]);
    setTranscript([]);
    setInput('');
    if (videoURL) {
      fetchTranscript();
    }
  }, [videoId]);

  useEffect(() => {
    window.seekTo = (timeInSeconds) => {
      if (player && isPlayerReady && player.seekTo) {
        player.seekTo(timeInSeconds);
        if (player.getPlayerState() !== 1) {
          player.playVideo();
        }
      } else {
        ////console.log("Player is not ready or seekTo is undefined.");
      }
    };
  }, [player, isPlayerReady]);

  const fetchTranscript = useCallback(async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ youtube_url: videoURL })
      };
      const transcriptRes = await fetch(apiURL + `/api/transcribe_youtube`, requestOptions);
      if (!transcriptRes.ok) {
        throw new Error('Error fetching transcript. Make sure it has an available transcript.');
      }
      const transcriptData = await transcriptRes.json();
      setTranscript(transcriptData.content);
    } catch (error) {
      console.error('Error fetching transcript:', error);
    }
  }, [videoURL]);

  const removeAsterisks = (text) => {
    return text.replace(/\*/g, '');
  };

  const highlightTimestamps = (text) => {
    return text.replace(/(\d{1,2}:\d{2}(?::\d{2})?)/g, (match, timestamp) => {
      const timeInSeconds = convertTimestampToSeconds(timestamp);
      return `<span style="color: #007fff; cursor: pointer;" onclick="window.seekTo(${timeInSeconds}); window.scrollTo({top: 0, behavior: 'smooth'});">${timestamp.trim()}</span>`;
    });
  };

  const convertTimestampToSeconds = (timestamp) => {
    const parts = timestamp.split(':').map(Number);
    if (parts.length === 2) {
      return parts[0] * 60 + parts[1];
    } else if (parts.length === 3) {
      return parts[0] * 3600 + parts[1] * 60 + parts[2];
    }
    return 0;
  };

  const sendTranscriptInChunks = async (transcript) => {
    ////console.log('Transcript is now:', transcript);

    const totalLength = transcript.length;
    const numChunks = Math.ceil(totalLength / 400);
    const chunkSize = Math.ceil(totalLength / numChunks);

    for (let i = 0; i < totalLength; i += chunkSize) {
      const chunkNumber = Math.floor(i / chunkSize) + 1;
      const chunk = transcript.slice(i, i + chunkSize);
      //console.log('Sending transcript chunk:', chunk);
      setIsTyping(true);
      try {
        const response = await fetch(apiURL + '/api/query_data', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            conversation: conversation.slice(-4),
            transcript_chunk: chunk,
            query: input,
            videoId,
            chromaPath,
            videoURL,
          }),
        });

        if (!response.ok) {
          throw new Error(`Server error: ${response.statusText}`);
        }

        let data = await response.json();
        
        //console.log('Received bot response:', data);

        let cleanedResponse = removeAsterisks(data.response);

        if (chunkNumber > 1) {
          if(cleanedResponse.length > 300){
            const colonIndex = cleanedResponse.indexOf(':');
            const periodIndex = cleanedResponse.indexOf('.');
    
            if (colonIndex !== -1 && (colonIndex < periodIndex || periodIndex === -4)) {
              cleanedResponse = cleanedResponse.slice(colonIndex + 1).trim();
            } else if (periodIndex !== -1) {
              cleanedResponse = cleanedResponse.slice(periodIndex + 1).trim();
            }
          }
        }

        cleanedResponse = highlightTimestamps(cleanedResponse);
        cleanedResponse = cleanedResponse.replace(/([^\S\n]){2,}/g, ' ').trim();

        if (cleanedResponse.includes('?') || cleanedResponse.length < 100 || input.length < 20 ) {
          const botMessage = { role: 'bot', content: cleanedResponse };
          setConversation(prevConversation => [...prevConversation, botMessage]);
          break;
        } else {
          const botMessage = {
            role: 'bot',
            content: numChunks > 1 ? `<span style="color: #bb00dd;">From part ${chunkNumber} of ${numChunks} of the video:</span><br/>` + cleanedResponse : cleanedResponse,
          };
          setConversation(prevConversation => [...prevConversation, botMessage]);
        }
      } catch (error) {
        console.error('Error querying the chatbot:', error);
        const errorMessage = { role: 'bot', content: 'There was an error processing your request. Please try again later.' };
        setConversation(prevConversation => [...prevConversation, errorMessage]);
      } finally{
        setIsTyping(false);
      }
    }  
  };

  const handleSend = async () => {
    if (input.trim() === '') return;

    const newMessage = { role: 'user', content: input };
    const updatedConversation = [...conversation, newMessage];

    setConversation(updatedConversation);
    setInput('');

    try {
      await sendTranscriptInChunks(transcript);
    } catch (error) {
      const errorMessage = { role: 'bot', content: 'There was an error fetching the transcript. Please try again later.' };
      setConversation(prevConversation => [...prevConversation, errorMessage]);
    }
  };

  const handleInputChange = (value) => {
    setInput(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [conversation, isOpen]);

  const handleMouseDown = (e) => {
    if (e.button !== 0) return;
    const pos = chatbotRef.current.getBoundingClientRect();
    setRel({
      x: e.pageX - pos.left,
      y: e.pageY - pos.top
    });
    setDragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleMouseUp = (e) => {
    setDragging(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.pageX - rel.x,
      y: e.pageY - rel.y
    });
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  });

  return (
    <div
      className="chatbot-container"
      ref={chatbotRef}
      style={{
        height: window.innerWidth <= 768 ? '80vh' : '600px',
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        overflow: 'hidden'
      }}
    >
      <MainContainer>
        <ChatContainer>
          <MessageList 
            typingIndicator={isTyping ? (
              <div style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                padding: '10px 0',
                borderTop: '1px solid #E5E5EA',
                zIndex: 1000
              }}>
                <TypingIndicator content="Bevi is typing..." />
              </div>
            ) : null} 
            style={{ 
              maxHeight: window.innerWidth <= 768 ? 'calc(80vh - 150px)' : 'calc(100vh - 150px)',
              overflowY: 'auto', 
              scrollbarWidth: 'none',
              paddingBottom: isTyping ? '50px' : '0'
            }} 
            ref={messageListRef}
          >
            {conversation.map((msg, index) => (
              <Message
                key={index}
                model={{
                  message: msg.content,
                  sentTime: "just now",
                  sender: msg.role === 'user' ? "User" : "Bevi",
                  direction: msg.role === 'user' ? "outgoing" : "incoming",
                }}
                className={msg.role === 'user' ? 'user-message' : 'ai-response'}
              />
            ))}
          </MessageList>

          <div as={MessageInput} style={{ display: "flex", alignItems: "center", borderTop: "2px rgb(45 47 49)"}}>
            <MessageInput
              placeholder="Type your message..."
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              style={{ 
                flex: 1, 
                minHeight: '50px', 
                maxHeight: '100px', 
                overflowY: 'auto',
                textAlign: 'left',
                paddingLeft: '15px',
                border: '1px solid #ccc',
                borderRadius: '5px',
                boxShadow: '0 .1px .1px rgba(0,0,0,0.1)',
                margin: '6px'
              }}
              attachButton={false}
              sendButton={false}
            />
            <SendButton onClick={handleSend} />
          </div>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default Chatbot;
