import React, { useState, useEffect } from 'react';
import './App.css'; // Ensure your CSS file contains the required styles
import MainContent from './Components/MainContent';
import Login from "./Components/auth/login";
import Header from "./Components/header";
import Register from "./Components/auth/register";
import { AuthProvider } from "./contexts/authContext";
import { useRoutes, Navigate } from "react-router-dom";
import Footer from "./Components/footer/footer";
import SummaryPage from "./Components/SummaryPage"; 
import PrivacyPolicy from './Components/PrivacyPolicy'; // Import the PrivacyPolicy component
import ProtectedRoute from './Components/ProtectedRoute'; // Import the ProtectedRoute component
import TermsAndConditions from './Components/TermsAndConditions'; // Import the TermsAndConditions component
import PricingTable from './Components/PricingTable';
import Success from './Components/Success'; // Import the Success component
import { fetchIpAddress, getSubscriptionStatus } from './Components/Services/CaptureIP';
import { useAuth } from './contexts/authContext';

 /***************************************************************************************** */
/***************************************************************************************** */
/***************************************************************************************** */

const Environment = "prod"; // Set the environment here. Production: "pro", Development: "dev" CHANGED

/***************************************************************************************** */
/***************************************************************************************** */

/***************************************************************************************** */
/*******************************  ********************************************************** */
/*******************************s********************************************************** */

var apiURL = Environment == "dev" ? apiURL = "http://127.0.0.1:8080" : apiURL = "https://api.bevi.ai";

var webURL = Environment == "dev" ? webURL = "http://localhost:3000" : webURL = "https://bevi.ai";


function App() {

  const [videoId, setVideoId] = useState("");
  const [urlCount, setUrlCount] = useState(0);
  const { currentUser : currentUsers } = useAuth();
  const [sub, setUserSub] = useState(null);
  const [userId, setUserId] = useState(null);
  const [ipAdd, setIpAddress] = useState('');

useEffect(() => {
  async function checkUserSubscription() {
    if (currentUsers) {
      try {
        const userSubs = await getSubscriptionStatus(apiURL, currentUsers.email);
   
        //console.log(JSON.stringify(userSubs))
        setUserSub(userSubs);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    }
  }
  // Call the async function
  checkUserSubscription();
}, [currentUsers]);


  //console.log('User Subscription:', JSON.stringify(sub))


  

   //get user IP address
   useEffect(() => {
    const getIp = async () => {
      const ip = await fetchIpAddress();
      setIpAddress(ip);
    };

    getIp();
  }, []); 

  useEffect(() => {
    const fetchUrlCount = async () => {
      //console.log('HERE HERE HER HER I AM CALLING IT FOR YOU')
      try {
        const response = await fetch(`${apiURL}/api/get_url_count_by_ip`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ip: ipAdd }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch URL count');
        }

        const data = await response.json();
        setUrlCount(data.count);
      } catch (error) {
        console.error('Error:', error);
        setUrlCount(0);
      }
    };

    if (ipAdd) {
      fetchUrlCount();
    }
  }, [ipAdd, currentUsers]);
  
  const routesArray = [
    {
      path: "*",
      element: <Navigate to="/home" />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
      
    },
    {
      path: "/home",
      element: (
       
          <MainContent apiURL={apiURL} webURL={webURL} userSub={sub} urlCount={urlCount}  userId={userId} />
       
      ),
    },
    {
      path: "/terms-and-conditions", // Route for Terms and Conditions
      element: 
      (
      <TermsAndConditions />

      ),
    },
    {
      path: "/privacy-policy", // Route for Terms and Conditions
      element: 
      (
      <PrivacyPolicy />

      ),
    },
    {
      path: "/summary/:linkId", // New route
      element: (
   
          <SummaryPage apiURL={apiURL} webURL={webURL} userSub={sub} urlCount={urlCount} setUrlCount={setUrlCount}  userId={userId} />
      ),
    },
    {
      path: "/products", // New route
      element: (

      <PricingTable  userSub={sub}/>
      
      ),
    },
    {
      path: "/success", // Add this route
      element: <Success />,
    },
  ];

  let routesElement = useRoutes(routesArray);

  // Add new useEffect to fetch user ID
  //console.log("LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")
  //console.log(currentUsers)
  //console.log(ipAdd)
  useEffect(() => {
    const fetchUserId = async () => {
      if (ipAdd) {
        try {
          const response = await fetch(`${apiURL}/api/get-user-id`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              firebase_uid: currentUsers?.uid || 'anonymous',
              ip: ipAdd ? ipAdd : '0.0.0.0'
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to fetch user ID');
          }

          const data = await response.json();
          setUserId(data.user_id);
          setUrlCount(data.url_count);
        } catch (error) {
          console.error('Error fetching user ID:', error);
          //alert('There was an issue fetching your user ID. Please try again later.'); // User-friendly error message
        }
      }
    };
    fetchUserId();
  }, [ipAdd, currentUsers]);



  //console.log("USER ID IS:")
  //console.log(userId)
  //console.log("urlCount ID IS:")
  //console.log(urlCount)
  return (
    <AuthProvider>
      <Header ipAdd={ipAdd} apiURL={apiURL} userSub={sub} urlCount={urlCount} userId={userId} />
      <div className="w-full flex flex-col">{routesElement}</div>
      <Footer  />
    </AuthProvider>
  );
}

export default App;
